@tailwind base;
@tailwind components;
@tailwind utilities;

/* Hero section styles */
@font-face {
  font-family: "Gilroy-Light";
  src: url("../src/assets/fonts/gilroy/FontsFree-Net-Gilroy-Light.ttf")
    format("truetype");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "Gilroy-Bold";
  src: url("../src/assets/fonts/gilroy/FontsFree-Net-Gilroy-Bold.ttf")
    format("truetype");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "Gilroy-XBold";
  src: url("../src/assets/fonts/gilroy/FontsFree-Net-Gilroy-ExtraBold.ttf")
    format("truetype");
  font-weight: bolder;
  font-style: normal;
}

@font-face {
  font-family: "Jost-Bold700";

  src: url("./assets/fonts/jost/Jost-Bold.ttf") format("truetype");
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: "Jost-Bold600";
  src: url("./assets/fonts/jost/Jost-SemiBold.ttf") format("truetype");
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: "Jost-Bold600";
  src: url("./assets/fonts/jost/Jost-SemiBold.ttf") format("truetype");
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: "Jost-Normal";
  src: url("./assets/fonts/jost/Jost-Regular.ttf") format("truetype");
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: "inter-Bold";
  src: url("./assets/fonts/inter/Inter-Medium.ttf") format("truetype");
  font-style: normal;
}

@font-face {
  font-family: "inter-Normal";
  src: url("./assets/fonts/inter/Inter-Regular.ttf") format("truetype");
  font-style: normal;
}

/* fonts for vriksh */

@font-face {
  font-family: "Jost-Bold";
  src: url("./myComponents/assets/fonts/jost/Jost-Bold.ttf") format("truetype");
}

@font-face {
  font-family: "Jost-ExtraBold";
  src: url("./myComponents/assets/fonts/jost/Jost-ExtraBold.ttf")
    format("truetype");
}

@font-face {
  font-family: "Jost-Medium";
  src: url("./myComponents/assets/fonts/jost/Jost-Medium.ttf")
    format("truetype");
}

@font-face {
  font-family: "Jost-Regular";
  src: url("./myComponents/assets/fonts/jost/Jost-Regular.ttf")
    format("truetype");
}

@font-face {
  font-family: "Jost-SemiBold";
  src: url("./myComponents/assets/fonts/jost/Jost-SemiBold.ttf")
    format("truetype");
}

@font-face {
  font-family: "Poppins-ExtraBold";
  src: url("./myComponents/assets/fonts/poppins/Poppins-ExtraBold.ttf")
    format("truetype");
}

@font-face {
  font-family: "Poppins-Bold";
  src: url("./myComponents/assets/fonts/poppins/Poppins-Bold.ttf")
    format("truetype");
}

@font-face {
  font-family: "Gilroy-Light";
  src: url("./myComponents/assets/fonts/gilroy/Gilroy-Light.ttf")
    format("truetype");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "Gilroy-Bold";
  src: url("./myComponents/assets/fonts/gilroy/Gilroy-Bold.ttf")
    format("truetype");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "Gilroy-XBold";
  src: url("./myComponents/assets/fonts/gilroy/Gilroy-ExtraBold.ttf")
    format("truetype");
  font-weight: bolder;
  font-style: normal;
}

@font-face {
  font-family: "inter-Bold";
  src: url("./myComponents/assets/fonts/inter/Inter-Bold.ttf")
    format("truetype");
  font-weight: 100;
  font-style: normal;
}

@font-face {
  font-family: "inter-Normal";
  src: url("./myComponents/assets/fonts/inter/Inter-Regular.ttf")
    format("truetype");
  font-style: normal;
}

body,

html {
  margin: 0;
  padding: 0;
 
}
body::-webkit-scrollbar {
  width: 10px;
}
 
body::-webkit-scrollbar-track {
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
}
 
body::-webkit-scrollbar-thumb {
  background-color: rgba(0, 0, 0, 0.484);
  border-radius: 10px;
}

.hover-parent:hover .hover-child-1 {
  opacity: 0;
}
.hover-parent:hover .hover-child-2 {
  opacity: 100;
}
.footer-bg {
  background-image: url("../src/Images/footer-bg.jpg");
  background-size: cover;
  background-position: center;
}

#hero {
  background-image: url("./assets/images/pexels.jpg");
  background-size: cover;
  background-position: center;
  padding: 100px 0;
  color: #fff;
  height: 620px;
}

#hero h2 {
  margin-top: 120px;
  font-size: 3em;
  margin-bottom: 20px;
  text-align: center;
  font-weight: bold;
}

#hero p {
  font-size: 1.2em;
  margin-bottom: 30px;
  text-align: center;
}

.btn-about {
  padding: 10px 20px;
  background-color: #dfdfdf;
  color: #204228;
  text-decoration: none;
  border-radius: 5px;
  font-weight: bold;
  transition: background-color 0.3s;
}

.btn:hover {
  background-color: #204228;
}
.paragraph {
  margin-bottom: 0.5em;
   /* Adjust the value as needed for the desired space */
}

/* ====================== */
