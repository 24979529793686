
  .bg-image {
    background-image:url(../assets/images/background.jpeg);
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center center;
    height: 400px;
    width: 100%;
  }

  .centered-text {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    color: #ffffff;
    text-align: center;
  }
  .content {
    position: relative;
    padding-bottom: 8vh;
    text-align: left;
    font-family: Lora, Arial;
    font-size: 12pt;
    font-weight: 600;
    vertical-align: baseline;
  }

  .card-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    padding: 20px;
    max-width: 1200px;
    margin: 0 auto;
    background-color: #fffaf3;
  }


  .card {
    flex-basis: calc(33.33% - 20px); /* Three cards in a row*/
    background-color: #fffaf3;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    margin-bottom: 35px;
    overflow: hidden;
    transition: transform 0.2s;
    border-radius: 40px;
  }

  .card:hover {
    transform: scale(1.05);
  }

  .card img {
    width: 100%;
    height: auto;
    display: block;
  }

  .card-content {
    padding: 20px;
    font-size: 25px;
  }

  .card-link {
    color: rgba(32, 66, 40, 1);
    font-style: oblique;
    font-family: 'Gilroy-Bold';
    font-size: xx-large;
    text-decoration: none !important;
  }

  .card-link:hover{
  text-decoration:underline  !important;
  }

  

  .centered-row {
    display: flex;
    justify-content: center;
    flex-basis: 100%;
  }

  section {
    background-color: #fffaf3 !important;
    text-align: left !important;
  
  }
  .wide-button {
    width: 50%; /* Adjust the width as needed */
    background-color: rgba(32, 66, 40, 1);
  }

  .wide-button:hover,
  .wide-button:active,
  .wide-button:focus {
    background-color: rgba(
      32,
      66,
      40,
      1
    ) !important ; /* Set the background color to the same color for all states */
    box-shadow: none !important; /* Remove the box shadow */
    border: none !important; /* Remove the border */
  }
  @media (max-width: 767px) {
    .card {
      flex-basis: 100%;
      margin-left: 5%;
      margin-right: 5%;
    }
    .wide-button {
      width: 100%;
    }
    .navbar-nav .nav-link {
      margin-right: 5px;
    }

    .content {
      position: relative;
      text-align: justify;
      margin-left: 3vh !important;
      margin-right: 3vh !important;
      margin-top: 8vh !important;
  }

    
  }