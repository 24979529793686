
*{
    box-sizing: border-box;
 }
 
 @font-face {
     font-family: 'Lora';
     src: url('./assets/fonts/Lora/static/Lora-Regular.ttf');
   }
   
   @font-face {
     font-family: 'Poppins';
     src: url('./assets/fonts/Poppins/Poppins-Regular.ttf');
   }
   
 body {
     font-family: 'Poppins', sans-serif;  
     font-size: 18px;
     
     
 
 }
 
 body > .skiptranslate {
     display: none;
   }
 
   .skiptranslate select{
     padding: 2px;
     border: 1px solid #204228;
     border-radius: 5px;
     margin-left: 10px;
     font-size: 14px;
     font-weight: 500;
     color: #204228;
   }
 
 .brand-logo{
     width: 40px;
     height: 40px;
     margin-right: 10px;
 }
 
 .brand-title{
     color: #204228;
     font-family: 'Lora', sans-serif;
     font-weight: bold;
     font-size: 22px;
 }
 
 .navbar-nav .nav-item .nav-link{
     font-weight: 500;
 }

 navbar-nav .nav-link {
    color: #204228;
    transition: color 0.3s ease;
}

.navbar-nav .nav-link:focus, .navbar-nav .nav-link:hover {
    color: #204228; /* Change to your preferred hover color */
}
 
.blog h1{
  font-size: 2.5rem !important;
  margin: 15px 0 !important;
}
.blog h2{
 font-size: 2.05 rem !important;
 margin: 11px 1px !important;
}
.blog h3{
 font-size: 1.5rem !important;
 margin: 8px 1px !important;
}
.blog h4{
 font-size: 1.25rem !important;
 margin: 8px 1px !important;
}
.blog h5{
 font-size: 1.15rem !important;
 margin: 6px 0 !important;
}
.blog h6{
 font-size: 1rem !important;
 margin: 6px 0 !important;
}
.blog ul{
  list-style-type: disc !important;
}

.tox .tox-statusbar {
  display: none !important;
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes fadeOut {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

.fade-enter {
  animation: fadeIn 1s forwards;
}

.fade-exit {
  animation: fadeOut 1s forwards;
}
